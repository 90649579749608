body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Allow container to grow */
  overflow-x: hidden; /* Allow vertical scrolling */
}

/* Flex container for the two cards */
.cards-column {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  align-items: center; /* Center the cards horizontally */
  justify-content: flex-start; /* Start at the top of the page */
  padding: 20px;
  margin-top: 0;
  height: 100%; /* Make sure the container takes full height */
  box-sizing: border-box; /* Ensure padding and margins don't affect the width/height */
}

/* Add spacing between the cards */
.cards-column > *:not(:last-child) {
  margin-bottom: 20px;
}

.projects-card {
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
} 
