/* Main container for the information card */
.info-card {
    position: relative;
    z-index: 1; /* Keep this above the background */
    max-width: 600px;
    margin: 50vh auto 0; /* Center horizontally, 50vh to center vertically */
    padding: 30px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.85); /* Slightly transparent white */
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    animation: fadeIn .25s ease-in-out; /* Smooth fade-in effect */
    margin-top: 75px;
  }
  
  /* Name styling */
  .info-card h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 25px;
    color: #333; /* Darker color for text */
  }
  
  /* Bio/description styling */
  .info-card p {
    font-size: 1.2rem;
    color: #666; /* Lighter gray for the description */
    margin-bottom: 15px;
  }
  
  /* Link styling */
  .info-card a {
    text-decoration: none;
    color: #007BFF; /* Subtle blue color for links */
    margin-inline: 15px;
    font-weight: 500;
    transition: color 0.2s;
  }
  
  .info-card a:hover {
    color: #0056b3; /* Slightly darker blue on hover */
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    0% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .info-card {
      max-width: 90%;
      padding: 20px;
    }
  
    .info-card h1 {
      font-size: 2rem;
    }
  
    .info-card p {
      font-size: 1rem;
    }
  }
  
.menu {
  margin-bottom: 20px;
}