/* Main container for the contact card */
.contact-card {
    position: relative;
    z-index: 1;
    max-width: 600px;
    margin: 20px auto;
    padding: 30px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn .25s ease-in-out;
    margin-top: 75px;
  }
  
  /* Title styling */
  .contact-card h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
  }
  
  /* Contact info styling */
  .contact-card p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 10px;
  }

    /* Fade-in animation */
    @keyframes fadeIn {
        0% {
            opacity: .5;
        }
        100% {
            opacity: 1;
        }
    }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .contact-card {
      max-width: 90%;
      padding: 20px;
    }
  
    .contact-card h2 {
      font-size: 1.8rem;
    }
  
    .contact-card p {
      font-size: 1rem;
    }
  }
  